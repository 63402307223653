.logo {
  width: 200px;
  height: auto;
}

.text {
  font-size: 18px;
  font-weight: 600;
}

.text a {
  color: #0a0a0a;
}

.text a:hover {
  color: #40c1c3;
}

.container {
  width: 100%;
  /* box-shadow: 0px 3px 10px 0px #8383835c; */
  position: absolute;
  top: 0;
  z-index: 99;
}
.mobile_menu_container {
  width: fit-content;
  display: flex;
}

/* @media only screen and (min-width: 991px) and (max-width: 1200px) {
  .text {
    font-size: 14.5px;
    font-weight: 600;
  }
} */
@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .text {
    font-size: 1.3vw;
    font-weight: 600;
  }
}

@media only screen and (max-width: 550px) {
  .logo {
    width: 140px;
    height: auto;
  }
}

@media only screen and (max-width: 350px) {
  .logo {
    width: 100px;
    height: auto;
  }
}
