.conatiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  aspect-ratio: 16/8;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}
