/* applyLogin.module.css */
.container {
  display: flex;
  height: 100vh;
  width: 100%;
  background: url("../../images/applyForm/bg-pic.png") no-repeat center center;
  background-size: cover;
}

.leftSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: 20px;
}
.leftSection img {
  max-width: 500px;
}

.formSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 100px 0 50px 100px;
  background: #eaf6f4;
}

.applyTab,
.loginTab {
  font-size: 40px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .leftSection {
    height: 200px;
    background-size: cover;
    padding: 10px;
  }
  .formSection {
    padding: 20px;
    padding-top: 100px;
  }
}
