.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.container span {
  font-size: 1em;
  font-weight: bold;
}

.qa_container_left {
  display: flex;
  flex-direction: column;
  padding-right: 5rem;
}
.qa_container_right {
  width: 60%;
}

.link_btn {
  margin-top: 1rem;
  color: inherit !important;
  transition: 0.3s;
  font-weight: bold;
}
.link_btn:hover {
  color: blueviolet !important;
}

.divider {
  width: clamp(300px, 100%, 1000px);
  height: 1px;
  background-color: #074445;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
