.container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  background-image: url(../../images/footer/footer.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;

  color: #0d4b4b;
}

.container a {
  color: inherit;
  cursor: pointer;
}

.container a:hover {
  color: #bdbdbd;
}

.section1_container {
  width: 100%;
  display: flex;
  justify-content: center;

  padding-top: 100px;
  padding-left: calc(5vw);
  padding-right: calc(5vw);
}

.container h3 {
  font-size: 18px;
  font-weight: normal;
}
.container h2 {
  font-size: 25px;
}

.container p {
  font-size: 15px;
  font-weight: normal;
}

.logo {
  width: 300px;
  height: auto;
}

.social_logo {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.column1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.column2 {
  display: flex;
  flex-direction: column;
  width: 50%;
  font-weight: 600;
}

.page_link {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}

.column3_1 {
  text-align: right;
}

.divider {
  width: 100%;
  border-top: 1px solid #054749;
  opacity: 1;
}

.container_mobile {
  display: flex;
  flex-direction: column;
  background-image: url(../../images/footer/footer.png);
  background-size: cover;
  color: #0d4b4b;
  font-weight: 600;
}

.section1_container_mobile a {
  color: inherit;
  cursor: pointer;
}

.section1_container_mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 2rem;
}

.logo_mobile {
  width: 100px;
  height: auto;
}

.social_logo_mobile {
  width: 25px;
  height: auto;
  cursor: pointer;
}

.page_link_mobile {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.page_link_mobile a {
  color: #0d4b4b;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}
.page_link_mobile span {
  white-space: nowrap;
}

.social_logo_wrapper {
  display: flex;
  justify-content: center;
  padding: 0 0 1rem 0;
}

@media only screen and (max-width: 960px) {
  .column1 {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 850px) {
  .page_link {
    white-space: unset;
  }
}
@media only screen and (max-width: 500px) {
  .divider {
    width: 100%;
  }
  .logo {
    width: 200px;
  }
}
