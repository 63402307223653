.container {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  width: 900px;
  height: 400px;

  background-image: url(../../images/aboutus/bg1.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-around;
}
.bg {
  position: relative;
  width: clamp(350px, 100%, 1000px);
}

.left_text {
  max-width: 350px;
  padding-top: 70px;
  padding-right: 15px;
}
.right_text {
  width: 390px;
  padding-top: 50px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_text_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-right: auto;
}

.icon_Text {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-right: auto;
}
.icon_Text span {
  color: #10463e;
  font-weight: 600;
}

.icon_Text img {
  width: 50px;
  height: auto;
}

@media only screen and (max-width: 800px) {
  .container {
    margin-top: 20px;
  }
  .left_text {
    padding: 0;
  }
  .icon_text_wrapper {
    margin: 0;
  }
}
