.container {
  display: flex;
  width: 100%;
  /* height: clamp(800px, 100%, 100vh); */
  place-content: center;

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;

  z-index: 10;
  clip-path: polygon(0 0%, 100% 0, 100% 89%, 0 100%);
}

.banner_text {
  color: #393939;
  width: fit-content;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 20%;
  left: 11%;
}

.banner_text1 span {
  font-size: 3rem;
  font-weight: 800;
}
.banner_text2 span {
  font-size: 2rem;
  font-weight: 700;
}

hr.banner-divider {
  border-top: 3px solid #4e4e4e;
  width: 5rem;
  width: 75%;
  opacity: 100;
  margin-top: 2px;
}

/* loanProduct_container for primereact carousel */
.loanProduct_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border: 1px solid #c4c4c44f;
  padding-top: 50px;
  padding-bottom: 100px;
  border-radius: 12px;
}

.iconText {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 30px;
}
.iconText img {
  width: 49px;
  margin-right: 30px;
}

.loanProduct_container h3 {
  font-size: 40px;
}
.loanProduct_container p {
  font-size: 18px;
  font-weight: 600;
}
/* min-width and max width */

@media only screen and (min-width: 1650px) {
  .container {
    background-position: 0px 0px;
  }
}

@media only screen and (min-width: 1921px) and (max-width: 2600px) {
  .container {
    background-position: center center;
  }
  .banner_text {
    top: 27%;
    left: 25%;
  }
  .banner_text1 span {
    font-size: 5rem;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .banner_text {
    top: 27%;
    left: 18%;
  }
  .banner_text1 span {
    font-size: 4rem;
  }
  .banner_text2 span {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .banner_text {
    top: 27%;
    left: 13%;
  }
  .banner_text1 span {
    font-size: 3rem;
  }
  .banner_text2 span {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1400px) {
  .container {
    background-size: auto;
  }
}

@media only screen and (max-width: 1360px) {
  .banner_text {
    scale: 0.7;
    left: calc(2vw - 50px);
  }
}

@media only screen and (max-width: 850px) {
  .container {
    height: 38vh;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    height: auto;
    clip-path: polygon(0 0%, 100% 0, 100% 94%, 0 100%);
  }
}
