.phoneBtn-container {
  z-index: 99;
  width: 49px;
  height: 49px;
  background: linear-gradient(90deg, #2ba4a6 0%, #6fc3c4 100%);
  display: flex;

  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.phoneIcon {
  width: 35px;
  height: auto;
}
